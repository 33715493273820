<template>
  <div class="w-l mx-auto" v-if="!question.isSurveyCompleted">
    <div class="pb-3 relative flex flex-row items-center">
      <div class="pr-1 mt-1 items-center" ref="questionIndicator">
        <h1 class="text-md-2 font-semibold text-grey-light">
          <span class="text-blue-navy">
            {{ question.currentQuestion }}
          </span>
          / {{ question.totalQuestions }}
        </h1>
      </div>
      <div class="flex-1">
        <div
          class="absolute h-8 bg-grey-light-1 rounded mx-auto z-20"
          :style="progressWidth"
        >
          <div
            class="h-8 bg-blue-navy rounded w-full transition-all"
            :style="{ width: completed }"
          ></div>
        </div>
      </div>
    </div>
    <Countdown
      v-if="!assessment.allowInMultipleSessions && assessment.timeAllocation"
      :startedAt="question.startedAt"
      :maxTimeAllocation="assessment.timeAllocation"
    />
    <div
      class="w-full flex flex-col justify-center mx-auto rounded shadow bg-white p-4"
    >
      <p class="font-barlow text-l text-grey-dark-2 font-bold ow">
        {{ question.question }}
      </p>
      <div class="w-full">
        <Answer
          class="my-2"
          v-for="answer in question.answers"
          :key="answer.id"
          :answer="answer.text"
          :group="question.id.toString()"
          @click.native="handleClick(answer.id)"
          :isActive="selectedAnswer === answer.id"
        />
      </div>
      <div class="w-full flex gap-3 mt-3">
        <Button
          v-if="assessment.allowingGoingBack"
          class="w-full mr-1 justify-center"
          type="secondary"
          @click.native="backwards"
          text="Back"
          :disabled="isFirstQuestion"
        />
        <Button
          class="w-full justify-center h-btn-lg"
          @click.native="forward"
          :text="isLastQuestion ? 'Finish' : 'Next'"
          :disabled="!selectedAnswer"
        />
      </div>
    </div>
    <div class="flex mt-5 flex-row justify-center pb-5">
      <img src="@/assets/img/logo-grey.svg" width="130" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import scrollToTop from "../../../services/utils/scrollToTop";
import Countdown from '../components/Countdown.vue';

export default {
  name: "Survey",
  components: {
    Countdown,
  },
  data: () => ({
    selectedAnswer: null,
    progressWidth: null,
  }),
  computed: {
    ...mapState({
      assessment: (state) => state.assessments.selectedAssessment,
      question: (state) => state.assessments.question,
    }),
    ...mapGetters({
      getCompletion: "assessments/getSelectedAssessmentCompletion",
    }),
    completed() {
      return this.getCompletion(true).total;
    },
    isLastQuestion() {
      return this.question.currentQuestion === this.assessment.totalQuestions;
    },
    isFirstQuestion() {
      return this.question.currentQuestion === 1;
    },
  },
  methods: {
    ...mapActions({
      next: "assessments/surveyForward",
      back: "assessments/surveyBackwards",
      finishSurvey: "assessments/finishSurvey",
    }),
    handleClick(id) {
      this.selectedAnswer = id;
    },
    async forward() {
      window.scrollTo(0, 0);
      const { answers } = await this.next({
        id: this.$route.params.id,
        questionId: this.question.id,
        answerId: this.selectedAnswer,
      })
      answers.forEach(res => {
        if (res.selected) {
          this.selectedAnswer = res.id;
        }
      });
      this.calculateBarWidth();
    },
    async backwards() {
      window.scrollTo(0, 0);
      const { answers } = await this.back({
        id: this.$route.params.id,
        currentQuestionId: this.question.id,
      });
      answers.forEach(res => {
        if (res.selected) {
          this.selectedAnswer = res.id;
        }
      });
      this.calculateBarWidth();
    },
    calculateBarWidth() {
      if (this.$refs.questionIndicator) {
        const width = `width: calc(100% - ${this.$refs.questionIndicator
          .clientWidth + 8}px);`;
        this.progressWidth = width;
      }
    },
  },
  mounted() {
    this.calculateBarWidth();
    scrollToTop();
  },
};
</script>
<style scoped lang="scss">
.ow {
  overflow-wrap: break-word;
  overflow: hidden;
}
</style>
