<template>
  <div class="flex justify-center mb-1 parent-container">
    <div class="hours container">
      <div> {{ displayHours }} </div>
      <div class="label text-sm">hours</div>
    </div>
    <span class="dots">:</span>
    <div class="minutes container">
      <p> {{ displayMinutes }} </p>
      <div class="label text-sm">minutes</div>
    </div>
    <span class="dots">:</span>
    <div class="seconds container">
      <p> {{ displaySeconds }} </p>
      <div class="label text-sm">seconds</div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Countdown",
  data: () => ({
    displayHours: 0,
    displayMinutes: 0,
    displaySeconds: 0,
    endDate: null,
    intervalId: null,
  }),
  props: {
    startedAt: { default: null, type: String },
    maxTimeAllocation: { default: null, type: String },
  },
  computed: {
    seconds() {
      return 1000;
    },
    minutes() {
      return this.seconds * 60;
    },
    hours() {
      return this.minutes * 60;
    }
  },
  mounted() {
    const firstDate = new Date(this.startedAt);
    this.endDate = this.createFinalDate(firstDate, this.maxTimeAllocation);
    this.showRemaining();
  },
  beforeDestroy() {
    this.stopCountdown();
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
    }),
    open() {
      this.openModal({ modal: "finishTime" });
    },
    formatNum(num) {
      return num < 10 ? '0' + num : num;
    },
    showRemaining() {
      this.intervalId = setInterval(() => {
        const now = new Date();
        const distance = this.endDate.getTime() - now.getTime();
        const hours = Math.max(0, Math.floor(distance / this.hours));
        const minutes = Math.max(0, Math.floor((distance % this.hours) / this.minutes));
        const seconds = Math.max(0, Math.floor((distance % this.minutes) / this.seconds));

        this.displaySeconds = this.formatNum(seconds);
        this.displayMinutes = this.formatNum(minutes);
        this.displayHours = this.formatNum(hours);
        
        if (distance <= 0 && hours === 0 && minutes === 0 && seconds === 0) {
          this.open();
          this.stopCountdown();
          return;
        }
      }, 1000);
    },
    createFinalDate(date, timeString) {
      if (timeString) {
        const [hours, minutes, seconds] = timeString.split(":").map(Number);
        return new Date(date.getTime() + hours * 3600000 + minutes * 60000 + seconds * 1000);
      }
    },
    stopCountdown() {
      clearInterval(this.intervalId);
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 50px;
  display: flex;
  padding: 0;
  margin: 0;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.parent-container {
  margin-right: auto;
  background-color: #0E6CAE;
  margin-left: auto;
  width: 200px;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0px 0px 20px 10px rgba(22, 121, 237, 0.04);
}
.dots {
  color: white;
}
</style>